<template>
  <div class="cards-link-container">
    <div class="cards-link">
      <h2 class="title">Le rendement locatif comme vous l’entendez</h2>
      <div class="card-container">
        <NuxtLink
          v-for="(card, index) in cards"
          :key="index"
          class="link"
          :href="card.url"
          :target="card.target"
          no-prefetch
        >
          <uikit-button
            type="secondary"
            block
            :textWrap="isMaxMobileLandscapeWidth"
          >
            {{ card.title }}
          </uikit-button>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import { UikitButton, useScreenSizeStore } from '@hz/ui-kit'
import { mapState } from 'pinia'

export default {
  components: {
    UikitButton,
  },
  computed: {
    ...mapState(useScreenSizeStore, ['isMaxMobileLandscapeWidth']),
  },
  data() {
    return {
      cards: [
        {
          title: 'Investissement clé en main',
          url: 'https://horiz.io/ressources/investissement-locatif-cle-en-main',
        },
        {
          title: 'Plugin gratuit rendement locatif',
          url: 'https://horiz.io/ressources/plugin-rendement-locatif',
        },
        {
          title: 'Annonce immos rendement locatif',
          url: this.$config.public.rlBaseUrl + '/investissement/annonces',
          target: '_blank',
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.cards-link {
  padding: 40px 30px;
  max-width: 1400px;
  margin: auto;

  .title {
    font-weight: var(--ds-weight-semi-bold);
    font-size: 24px;
    color: white;
    margin: 0;
    text-align: center;
  }

  .link {
    width: 100%;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 400px;
    margin: 40px auto 0;

    .card {
      display: flex;
      background: #ffffff;
      border: 2px solid #f4f7fb;
      border-radius: 14.0529px;
      align-items: center;
      color: #272727;
      text-decoration: none;
      margin: 6px 0;
      min-width: 50%;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
    }
  }

  @media @bp-desktop {
    padding: 64px 80px;

    .title {
      font-size: 32px;
    }

    .link {
      width: unset;
    }

    .card-container {
      flex-direction: row;
      justify-content: space-evenly;
      max-width: 100%;

      .card {
        padding: 10px 36px;
        justify-content: center;
        min-width: 350px;
        margin: 6px auto;
      }
    }
  }
}
</style>
